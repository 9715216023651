.custom-divider.ant-divider-horizontal.ant-divider-with-text-left::before,
.custom-divider.ant-divider-horizontal.ant-divider-with-text-left::after {
    border-top: 1px solid #CDE6C8; /* Change the color to #93B3A5 */
}


.red-divider.ant-divider-horizontal.ant-divider-with-text-left::before,
.red-divider.ant-divider-horizontal.ant-divider-with-text-left::after {
    border-top: 1px solid #ED9F9B; /* Change the color to #93B3A5 */
}

.description {
    font-size: 20px;
}

.custom-panel {
    background: white !important;
    font-size: 18px; 
}

.equation{
    display: 'block';
    text-align: center;
}